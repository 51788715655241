// Generated by Framer (015b6bb)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/rQJeCcsGr1fSnWO0FT2G/Hero.js";
import * as sharedStyle from "../css/Vfws55ZeQ";
const HeroFonts = getFonts(Hero);
const HeroControls = getPropertyControls(Hero);

const cycleOrder = ["LMK0IInNK"];

const serializationHash = "framer-l0jeu"

const variantClassNames = {LMK0IInNK: "framer-v-shjdok"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, select, title, width, ...props}) => { return {...props, p_Y05DKQi: title ?? props.p_Y05DKQi ?? "55,000+ Business Trust", PGAqUpbLJ: select ?? props.PGAqUpbLJ ?? true, XsbZWUBkW: icon ?? props.XsbZWUBkW ?? "Home"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;select?: boolean;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, p_Y05DKQi, PGAqUpbLJ, XsbZWUBkW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LMK0IInNK", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-shjdok", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LMK0IInNK"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(244, 241, 253)", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99, ...style}}><ComponentViewportProvider ><motion.div className={"framer-1v1ydvl-container"} layoutDependency={layoutDependency} layoutId={"yrUhqSF1L-container"}><Hero color={"rgb(102, 102, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={XsbZWUBkW} id={"yrUhqSF1L"} layoutId={"yrUhqSF1L"} mirrored selectByList={PGAqUpbLJ} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-fgjv3y"} data-styles-preset={"Vfws55ZeQ"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-503bf229-0e95-47c2-9491-d0835961a290, rgb(52, 61, 127)))"}}>55,000+ Business Trust</motion.p></React.Fragment>} className={"framer-16vfxvv"} data-framer-name={"Sub Heading"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"BdgURc2rs"} style={{"--extracted-r6o4lv": "var(--token-503bf229-0e95-47c2-9491-d0835961a290, rgb(52, 61, 127))", "--framer-paragraph-spacing": "0px"}} text={p_Y05DKQi} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l0jeu.framer-w4drdm, .framer-l0jeu .framer-w4drdm { display: block; }", ".framer-l0jeu.framer-shjdok { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 4px 16px 4px 16px; position: relative; width: min-content; }", ".framer-l0jeu .framer-1v1ydvl-container { flex: none; height: 14px; position: relative; width: 14px; }", ".framer-l0jeu .framer-16vfxvv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-l0jeu.framer-shjdok { gap: 0px; } .framer-l0jeu.framer-shjdok > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-l0jeu.framer-shjdok > :first-child { margin-left: 0px; } .framer-l0jeu.framer-shjdok > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 216.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"p_Y05DKQi":"title","PGAqUpbLJ":"select","XsbZWUBkW":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjW3AM9PUy: React.ComponentType<Props> = withCSS(Component, css, "framer-l0jeu") as typeof Component;
export default FramerjW3AM9PUy;

FramerjW3AM9PUy.displayName = "Tag";

FramerjW3AM9PUy.defaultProps = {height: 32, width: 216.5};

addPropertyControls(FramerjW3AM9PUy, {p_Y05DKQi: {defaultValue: "55,000+ Business Trust", displayTextArea: false, title: "Title", type: ControlType.String}, PGAqUpbLJ: {defaultValue: true, title: "Select", type: ControlType.Boolean}, XsbZWUBkW: HeroControls?.["iconSelection"] && {...HeroControls["iconSelection"], defaultValue: "Home", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(FramerjW3AM9PUy, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...HeroFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})